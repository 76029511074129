import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import ImageWithSideText from './project-components/chapter-types/imageWithSideText'
import * as hiringStyles from './hiring.module.scss'

const HiringPage = ({ payload }) => {
    // const [countryState, setCountry] = useState('All')
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        runOpeningAnimation: {
            id: '',
            effect: effects.ON_OPEN,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const orderedPositions = () => {
        const positionList = payload.allWpJob.edges
        return payload.wpPage.hiring_page.orderObjects
            .map((orderObj) => {
                let currentNode = null
                positionList.forEach((node) => {
                    if (orderObj.title === node.node.title) {
                        currentNode = node
                    }
                })
                return currentNode
            })
            .filter((checkForNull) => checkForNull !== null)
    }

    const positions = orderedPositions().map(
        (edge) => (
            <div
                className={hiringStyles.positions}
                key={`positions-${edge.node.title}`}
            >
                <Link
                    style={{ textDecoration: 'none' }}
                    to={`/${edge.node.slug}`}
                >
                    <h3 style={{ cursor: 'pointer' }}>{edge.node.title}</h3>
                    <h4 style={{ cursor: 'pointer' }}>
                        <span className={hiringStyles.arrow}/>
                    </h4>
                    <p>{`in ${edge.node.job.location}`}</p>
                </Link>
            </div>
        )
        // if (countryState === 'All' || countryState === edge.node.job.jobType) {
        //     return (
        //         <div
        //             className={hiringStyles.positions}
        //             key={`positions-${edge.node.title}`}
        //         >
        //             <Link
        //                 style={{ textDecoration: 'none' }}
        //                 to={`/${edge.node.slug}`}
        //             >
        //                 <h3 style={{ cursor: 'pointer' }}>{edge.node.title}</h3>
        //                 <h4 style={{ cursor: 'pointer' }}>+</h4>
        //             </Link>
        //         </div>
        //     )
        // }
        // return null
    )

    // const jobLocations = ['All']
    // payload.allWpJob.edges.forEach((edge) => {
    //     if (!jobLocations.includes(edge.node.job.jobType)) {
    //         jobLocations.push(edge.node.job.jobType)
    //     }
    // })

    // const countryButtons = jobLocations.map((county) => (
    //     <p
    //         onClick={() => setCountry(county)}
    //         style={
    //             county === countryState
    //                 ? {
    //                       fontWeight: 'bold',
    //                       color: '#0064ff',
    //                       cursor: 'pointer',
    //                   }
    //                 : { cursor: 'pointer', color: '#a4a4a4' }
    //         }
    //         className={hiringStyles.selector}
    //         key={county}
    //     >
    //         {county}
    //     </p>
    // ))

    const conditionalMedia = () => {
        if (payload.wpPage.hiring_page.video) {
            return [
                {
                    __typename: 'custom_MixedMedia_Video',
                    noControls: true,
                    autoplay: true,
                    loop: true,
                    video: payload.wpPage.hiring_page.video,
                },
            ]
        }
        return null
    }

    return (
        <div className={hiringStyles.heroContainer}>
            <div
                className={
                    animationStates.runOpeningAnimation
                        ? hiringStyles.titleBox
                        : hiringStyles.titleBoxClosed
                }
            >
                <h1>{payload.wpPage.hiring_page.title}</h1>
            </div>
            <div className={hiringStyles.margin}>
                <div className={hiringStyles.positionsContainer}>
                    <div className={hiringStyles.coloredBox}>
                        <h2>{payload.wpPage.hiring_page.welcomeText}</h2>

                        {/* <div className={hiringStyles.selectors}>
                        {countryButtons}
                    </div> */}
                        <div className={hiringStyles.positionsBox}>
                            {positions}
                        </div>
                    </div>
                </div>
            </div>
            {payload.wpPage.hiring_page.showMediaBox ? (
                <ImageWithSideText
                    index={1}
                    media={conditionalMedia()}
                    showHeadings={
                        payload.wpPage.hiring_page.showHeadings || false
                    }
                    heading={payload.wpPage.hiring_page.heading || ''}
                    subHeading={payload.wpPage.hiring_page.subheading || ''}
                    backgroundColor={'white'}
                    color={payload.wpPage.hiring_page.textColour}
                    show={payload.wpPage.hiring_page.showText || false}
                    description={payload.wpPage.hiring_page.description || ''}
                    stickyText={false}
                    horizontalAlignment={'left'}
                    key={`imageside-chapter-${1}`}
                />
            ) : null}
        </div>
    )
}

export default HiringPage
