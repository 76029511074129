import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hiring from '../components/hiring'
import useHiringData from '../components/custom-hooks/useHiringData'

const HirePage = () => {
    const payload = useHiringData()

    return (
        <Layout>
            <SEO title="We're Hiring!" />
            <Hiring payload={payload} />
        </Layout>
    )
}

export default HirePage
