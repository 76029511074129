import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(title: { eq: "Hiring" }) {
                    hiring_page {
                        title
                        description
                        textColour
                        heading
                        subheading
                        showText
                        showHeadings
                        showMediaBox
                        welcomeText
                        orderObjects {
                            ... on WpJob {
                                id
                                title
                            }
                        }
                        video {
                            mediaItemUrl
                        }
                    }
                }
                allWpJob {
                    edges {
                        node {
                            title
                            slug
                            job {
                                location
                                jobType
                            }
                        }
                    }
                }
            }
        `
    )
    return payload
}
