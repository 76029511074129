// extracted by mini-css-extract-plugin
export const heroContainer = "hiring-module--hero-container--2Fqdb";
export const titleBox = "hiring-module--title-box--1MU8Q";
export const titleBoxClosed = "hiring-module--title-box-closed--udD1Q";
export const margin = "hiring-module--margin--1aOxk";
export const positionsContainer = "hiring-module--positions-container--3vKIO";
export const coloredBox = "hiring-module--colored-box--1fMVJ";
export const positionsBox = "hiring-module--positions-box--1fGjz";
export const positions = "hiring-module--positions--1ttkJ";
export const arrow = "hiring-module--arrow--1kvEO";
export const welcome = "hiring-module--welcome--ucIuY";
export const selectors = "hiring-module--selectors--32PwF";
export const selector = "hiring-module--selector--19oPd";